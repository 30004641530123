//
// Footer
//

.footer {
    background-color: get($footer-config, bg-color);
    color: white;

    .links {
        ul {
            list-style-type: none;
        }
        li a {
            color: white;
            transition: color 0.2s;
            &:hover {
                text-decoration: none;
                color: #d04730;
            }
        }
    }
    .about-company {
        i {
            font-size: 25px;
        }
        a {
            color: white;
            transition: color 0.2s;
            &:hover {
                color: #d04730;
            }
        }
    }
    .location {
        i {
            font-size: 18px;
        }
    }
    .copyright p {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
}
