.wrapper-loading{
    width: 200px;
    height: 60px;
    margin-top: 5rem;
    margin-bottom: 2rem;
    margin-left: 50%;
    margin-right: 50%;
    position: relative;
    transform: translate(-50%, -50%);

    span {
        position: absolute;
        width: 300px;
        top: 75px;
        font-family: 'Lato';
        font-size: 20px;
        letter-spacing: 2px;
        color: #fff;
        left: 5%;
    }
}

.circle-loading{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    background-color: #F8C902;
    left:15%;
    transform-origin: 50%;
    animation: circleloading .5s alternate infinite ease;
}

.circle-loading:nth-child(2){
    background-color: #D04730;
    left:45%;
    animation-delay: .2s;
}
.circle-loading:nth-child(3){
    background-color: #be1c3d;
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.shadow-loading{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadowloading .5s alternate infinite ease;
}

.shadow-loading:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.shadow-loading:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
}

// ANIMATIONS
@keyframes circleloading{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}

@keyframes shadowloading{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
