/**********************/
/* CARDS ESCORTS      */
/**********************/

.more-filter {
    width: 100%;
    outline: none;
    margin-top: 22px;
    height: 42px;
    background: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 5px;
}

.wrapper-card {
    margin: 0 auto;
    width: 100%;

    .cards {
        background-color: #1e1e2d;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
        background-position: center;
        overflow: hidden;
        cursor: pointer;
        list-style: none;
        margin: 0;
        padding: 0;

        &.grid-1 {
            margin-top: 2%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            /*grid-template-columns: repeat(auto-fill, minmax(200px 1fr));*/
            grid-gap: 10px;

            // X-Large devices (large desktops, less than 1400px)
            @media (max-width: 1399.98px) {
                grid-template-columns: repeat(3, 1fr);
            }

            // Medium devices (tablets, less than 992px)
            @media (max-width: 991.98px) {
                grid-template-columns: repeat(2, 1fr);
            }

            // X-Small devices (portrait phones, less than 576px)
            @media (max-width: 575.98px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                height: 450px;
                max-height: 450px;

                .main-img {
                    height: 450px;
                }
            }
        }

        &.grid-2 {
            margin-top: 2%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            /*grid-template-columns: repeat(auto-fill, minmax(200px 1fr));*/
            grid-gap: 20px;

            // X-Large devices (large desktops, less than 1400px)
            @media (max-width: 1399.98px) {
                grid-template-columns: repeat(4, 1fr);
            }

            // Medium devices (tablets, less than 992px)
            @media (max-width: 991.98px) {
                grid-template-columns: repeat(3, 1fr);
            }

            // X-Small devices (portrait phones, less than 576px)
            @media (max-width: 575.98px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                height: 350px;
                max-height: 350px;

                .main-img {
                    height: 350px;
                }
            }
        }

        &.grid-3 {
            margin-top: 2%;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            /*grid-template-columns: repeat(auto-fill, minmax(200px 1fr));*/
            grid-gap: 20px;

            // X-Large devices (large desktops, less than 1400px)
            @media (max-width: 1399.98px) {
                grid-template-columns: repeat(5, 1fr);
            }

            // Medium devices (tablets, less than 992px)
            @media (max-width: 991.98px) {
                grid-template-columns: repeat(4, 1fr);
            }

            // X-Small devices (portrait phones, less than 576px)
            @media (max-width: 575.98px) {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                height: 250px;
                max-height: 250px;

                .main-img {
                    height: 250px;
                }
            }
        }

        li {
            border: 2px solid #fff;
            border-radius: 5px;
            border-bottom-right-radius: 40px;
            border-bottom-left-radius: 40px;
            overflow: hidden;
            position: relative;

            .player-wrapper {
                position: relative;
                padding-top: 56.25%; /* 720 / 1280 = 0.5625 */

                .react-player {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .main-img {
                display: block;
                width: 100%;
                float: left;
                position: relative;

                // display: block;
                // margin-left: auto;
                // margin-right: auto;
                // height: auto;
                // max-width: 100%;
                // max-height: 100%;
            }

            .bottom {
                position: absolute;
                width: 100%;
                text-align: center;
                margin: 0 auto;
                padding-top: 1rem;
                padding-left: 2rem;
                padding-right: 2rem;
                bottom: 0;
                background: rgba(0, 0, 0, 0.4);

                .escort-name {
                    text-transform: uppercase;
                    color: #fff;
                    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
                    font-weight: bold;
                }

                .escort-age-city {
                    text-transform: uppercase;
                    color: #fff;
                    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
                    font-weight: bold;
                }
            }

            .call-wrapper {
                position: absolute;
                bottom: 5px;
                left: -5rem;
                .ring-effect-ph-circle {
                    width: 160px;
                    height: 160px;
                    top: 20px;
                    left: 20px;
                    position: absolute;
                    background-color: transparent;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    border-radius: 100%;
                    border: 2px solid rgb(208, 71, 48, 0.4);
                    opacity: 0.1;
                    -webkit-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    -moz-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    -ms-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    -o-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    animation: ring-effect-circle-anim 1.2s infinite ease-in-out;
                    -webkit-transition: all 0.5s;
                    -moz-transition: all 0.5s;
                    -o-transition: all 0.5s;
                    transition: all 0.5s;
                }

                .ring-effect-phone {
                    background-color: transparent;
                    width: 130px;
                    height: 130px;
                    cursor: pointer;
                    // z-index: 200000 !important;
                    -webkit-backface-visibility: hidden;
                    -webkit-transform: translateZ(0);
                    -webkit-transition: visibility 0.5s;
                    -moz-transition: visibility 0.5s;
                    -o-transition: visibility 0.5s;
                    transition: visibility 0.5s;
                    right: 150px;
                    top: 30px;
                }

                .ring-effect-phone.ring-effect-green
                    .ring-effect-ph-circle-fill {
                    background-color: rgb(208, 71, 48, 0.5);
                    opacity: 0.75 !important;
                }

                .ring-effect-ph-circle-fill {
                    width: 100px;
                    height: 100px;
                    top: 50px;
                    left: 50px;
                    position: absolute;
                    background-color: #000;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    border-radius: 100%;
                    border: 2px solid transparent;
                    opacity: 0.1;
                    -webkit-animation: ring-effect-circle-fill-anim 2.3s
                        infinite ease-in-out;
                    -moz-animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    -ms-animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    -o-animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    -webkit-transition: all 0.5s;
                    -moz-transition: all 0.5s;
                    -o-transition: all 0.5s;
                    transition: all 0.5s;
                }

                .ring-effect-ph-img-circle {
                    width: 60px;
                    height: 60px;
                    top: 70px;
                    left: 70px;
                    position: absolute;
                    background: rgb(208, 71, 48, 0.1)
                        url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==")
                        no-repeat center center;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    border-radius: 100%;
                    border: 2px solid transparent;
                    opacity: 0.7;
                    -webkit-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    -moz-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    -ms-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    -o-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                }

                .ring-effect-phone.ring-effect-green
                    .ring-effect-ph-img-circle {
                    background-color: #d04730;
                }

                .ring-effect-phone.ring-effect-green .ring-effect-ph-circle {
                    border-color: #d04730;
                    opacity: 0.5;
                }

                .ring-effect-phone.ring-effect-green.ring-effect-hover
                    .ring-effect-ph-circle,
                .ring-effect-phone.ring-effect-green:hover
                    .ring-effect-ph-circle {
                    border-color: #d04730;
                    opacity: 0.5;
                }

                .ring-effect-phone.ring-effect-green.ring-effect-hover
                    .ring-effect-ph-circle-fill,
                .ring-effect-phone.ring-effect-green:hover
                    .ring-effect-ph-circle-fill {
                    background-color: rgb(208, 71, 48, 0.5);
                    opacity: 0.75 !important;
                }

                .ring-effect-phone.ring-effect-green.ring-effect-hover
                    .ring-effect-ph-img-circle,
                .ring-effect-phone.ring-effect-green:hover
                    .ring-effect-ph-img-circle {
                    background-color: #d04730;
                }

                .ring-button {
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    background: transparent;
                    border: none;
                }
            }

            .whatsapp-wrapper {
                position: absolute;
                bottom: 5px;
                right: 5px;

                .ring-effect-ph-circle {
                    width: 160px;
                    height: 160px;
                    top: 20px;
                    left: 20px;
                    position: absolute;
                    background-color: transparent;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    border-radius: 100%;
                    border: 2px solid rgb(57, 191, 70, 0.4);
                    opacity: 0.1;
                    -webkit-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    -moz-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    -ms-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    -o-animation: ring-effect-circle-anim 1.2s infinite
                        ease-in-out;
                    animation: ring-effect-circle-anim 1.2s infinite ease-in-out;
                    -webkit-transition: all 0.5s;
                    -moz-transition: all 0.5s;
                    -o-transition: all 0.5s;
                    transition: all 0.5s;
                }

                .ring-effect-phone {
                    background-color: transparent;
                    width: 130px;
                    height: 130px;
                    cursor: pointer;
                    // z-index: 200000 !important;
                    -webkit-backface-visibility: hidden;
                    -webkit-transform: translateZ(0);
                    -webkit-transition: visibility 0.5s;
                    -moz-transition: visibility 0.5s;
                    -o-transition: visibility 0.5s;
                    transition: visibility 0.5s;
                    right: 150px;
                    top: 30px;
                }

                .ring-effect-phone.ring-effect-green
                    .ring-effect-ph-circle-fill {
                    background-color: rgb(57, 191, 70, 0.5);
                    opacity: 0.75 !important;
                }

                .ring-effect-ph-circle-fill {
                    width: 100px;
                    height: 100px;
                    top: 50px;
                    left: 50px;
                    position: absolute;
                    background-color: #000;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    border-radius: 100%;
                    border: 2px solid transparent;
                    opacity: 0.1;
                    -webkit-animation: ring-effect-circle-fill-anim 2.3s
                        infinite ease-in-out;
                    -moz-animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    -ms-animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    -o-animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    animation: ring-effect-circle-fill-anim 2.3s infinite
                        ease-in-out;
                    -webkit-transition: all 0.5s;
                    -moz-transition: all 0.5s;
                    -o-transition: all 0.5s;
                    transition: all 0.5s;
                }

                .ring-effect-ph-img-circle {
                    width: 60px;
                    height: 60px;
                    top: 70px;
                    left: 70px;
                    position: absolute;
                    -webkit-border-radius: 100%;
                    -moz-border-radius: 100%;
                    border-radius: 100%;
                    border: 2px solid transparent;
                    opacity: 0.7;
                    -webkit-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    -moz-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    -ms-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    -o-animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                    animation: ring-effect-circle-img-anim 1s infinite
                        ease-in-out;
                }

                .ring-effect-phone.ring-effect-green
                    .ring-effect-ph-img-circle {
                    background-color: #39bf46;
                }

                .ring-effect-phone.ring-effect-green .ring-effect-ph-circle {
                    border-color: #39bf46;
                    opacity: 0.5;
                }

                .ring-effect-phone.ring-effect-green.ring-effect-hover
                    .ring-effect-ph-circle,
                .ring-effect-phone.ring-effect-green:hover
                    .ring-effect-ph-circle {
                    border-color: #39bf46;
                    opacity: 0.5;
                }

                .ring-effect-phone.ring-effect-green.ring-effect-hover
                    .ring-effect-ph-circle-fill,
                .ring-effect-phone.ring-effect-green:hover
                    .ring-effect-ph-circle-fill {
                    background-color: rgb(57, 191, 70, 0.5);
                    opacity: 0.75 !important;
                }

                .ring-effect-phone.ring-effect-green.ring-effect-hover
                    .ring-effect-ph-img-circle,
                .ring-effect-phone.ring-effect-green:hover
                    .ring-effect-ph-img-circle {
                    background-color: #39bf46;
                }

                .ring-button {
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    background: transparent;
                    border: none;
                }
            }
        }
    }
}

// Escort card
.escort-card {
    // background-color: #1e1e2d;
    // box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    // background-position: center;
    // overflow: hidden;
    // position: relative;
    // margin: 10px auto;
    // cursor: pointer;
    // border-radius: 10px;

    // .player-wrapper {
    //     position: relative;
    //     padding-top: 56.25%; /* 720 / 1280 = 0.5625 */

    //     .react-player {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //     }
    // }

    // .escort-name {
    //     position: absolute;
    //     text-transform: uppercase;
    //     left: 30px;
    //     bottom: 100px;
    //     width: 70%;
    //     color: #fff;
    //     text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    //     font-weight: bold;
    //     transition: all linear 0.25s;
    // }

    // .escort-age-city {
    //     position: absolute;
    //     text-transform: uppercase;
    //     left: 30px;
    //     bottom: 80px;
    //     width: 70%;
    //     color: #fff;
    //     text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    //     font-weight: bold;
    //     transition: all linear 0.25s;
    // }

    // .escort-star {
    //     position: absolute;
    //     bottom: 10px;
    //     left: 30px;
    //     color: #fff;
    //     font-size: 13px;
    //     transition: all linear 0.25s;
    //     width: 50%;

    //     ul {
    //         display: inline-block;
    //         margin: 0;
    //         padding: 0;
    //         width: 100%;
    //         list-style-type: none;

    //         li {
    //             float: left;
    //             width: 20%;
    //         }
    //     }
    // }

    // .whatsapp-wrapper {
    //     position: absolute;
    //     bottom: 2px;
    //     right: 2px;

    //     .ring-effect-ph-circle {
    //         width: 160px;
    //         height: 160px;
    //         top: 20px;
    //         left: 20px;
    //         position: absolute;
    //         background-color: transparent;
    //         -webkit-border-radius: 100%;
    //         -moz-border-radius: 100%;
    //         border-radius: 100%;
    //         border: 2px solid rgb(57, 191, 70, 0.4);
    //         opacity: 0.1;
    //         -webkit-animation: ring-effect-circle-anim 1.2s infinite ease-in-out;
    //         -moz-animation: ring-effect-circle-anim 1.2s infinite ease-in-out;
    //         -ms-animation: ring-effect-circle-anim 1.2s infinite ease-in-out;
    //         -o-animation: ring-effect-circle-anim 1.2s infinite ease-in-out;
    //         animation: ring-effect-circle-anim 1.2s infinite ease-in-out;
    //         -webkit-transition: all 0.5s;
    //         -moz-transition: all 0.5s;
    //         -o-transition: all 0.5s;
    //         transition: all 0.5s;
    //     }

    //     .ring-effect-phone {
    //         background-color: transparent;
    //         width: 130px;
    //         height: 130px;
    //         cursor: pointer;
    //         // z-index: 200000 !important;
    //         -webkit-backface-visibility: hidden;
    //         -webkit-transform: translateZ(0);
    //         -webkit-transition: visibility 0.5s;
    //         -moz-transition: visibility 0.5s;
    //         -o-transition: visibility 0.5s;
    //         transition: visibility 0.5s;
    //         right: 150px;
    //         top: 30px;
    //     }

    //     .ring-effect-phone.ring-effect-green .ring-effect-ph-circle-fill {
    //         background-color: rgb(57, 191, 70, 0.5);
    //         opacity: 0.75 !important;
    //     }

    //     .ring-effect-ph-circle-fill {
    //         width: 100px;
    //         height: 100px;
    //         top: 50px;
    //         left: 50px;
    //         position: absolute;
    //         background-color: #000;
    //         -webkit-border-radius: 100%;
    //         -moz-border-radius: 100%;
    //         border-radius: 100%;
    //         border: 2px solid transparent;
    //         opacity: 0.1;
    //         -webkit-animation: ring-effect-circle-fill-anim 2.3s infinite
    //             ease-in-out;
    //         -moz-animation: ring-effect-circle-fill-anim 2.3s infinite
    //             ease-in-out;
    //         -ms-animation: ring-effect-circle-fill-anim 2.3s infinite
    //             ease-in-out;
    //         -o-animation: ring-effect-circle-fill-anim 2.3s infinite ease-in-out;
    //         animation: ring-effect-circle-fill-anim 2.3s infinite ease-in-out;
    //         -webkit-transition: all 0.5s;
    //         -moz-transition: all 0.5s;
    //         -o-transition: all 0.5s;
    //         transition: all 0.5s;
    //     }

    //     .ring-effect-ph-img-circle {
    //         width: 60px;
    //         height: 60px;
    //         top: 70px;
    //         left: 70px;
    //         position: absolute;
    //         background: rgb(57, 191, 70, 0.1)
    //             url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==")
    //             no-repeat center center;
    //         -webkit-border-radius: 100%;
    //         -moz-border-radius: 100%;
    //         border-radius: 100%;
    //         border: 2px solid transparent;
    //         opacity: 0.7;
    //         -webkit-animation: ring-effect-circle-img-anim 1s infinite
    //             ease-in-out;
    //         -moz-animation: ring-effect-circle-img-anim 1s infinite ease-in-out;
    //         -ms-animation: ring-effect-circle-img-anim 1s infinite ease-in-out;
    //         -o-animation: ring-effect-circle-img-anim 1s infinite ease-in-out;
    //         animation: ring-effect-circle-img-anim 1s infinite ease-in-out;
    //     }

    //     .ring-effect-phone.ring-effect-green .ring-effect-ph-img-circle {
    //         background-color: #39bf46;
    //     }

    //     .ring-effect-phone.ring-effect-green .ring-effect-ph-circle {
    //         border-color: #39bf46;
    //         opacity: 0.5;
    //     }

    //     .ring-effect-phone.ring-effect-green.ring-effect-hover
    //         .ring-effect-ph-circle,
    //     .ring-effect-phone.ring-effect-green:hover .ring-effect-ph-circle {
    //         border-color: #39bf46;
    //         opacity: 0.5;
    //     }

    //     .ring-effect-phone.ring-effect-green.ring-effect-hover
    //         .ring-effect-ph-circle-fill,
    //     .ring-effect-phone.ring-effect-green:hover .ring-effect-ph-circle-fill {
    //         background-color: rgb(57, 191, 70, 0.5);
    //         opacity: 0.75 !important;
    //     }

    //     .ring-effect-phone.ring-effect-green.ring-effect-hover
    //         .ring-effect-ph-img-circle,
    //     .ring-effect-phone.ring-effect-green:hover .ring-effect-ph-img-circle {
    //         background-color: #39bf46;
    //     }

    //     .ring-button {
    //         width: 60px;
    //         height: 60px;
    //         border-radius: 30px;
    //         background: transparent;
    //         border: none;
    //     }
    // }

    // .main-img {
    //     transition: all linear 0.25s;
    //     filter: brightness(70%);
    //     opacity: 0.9;
    //     display: block;
    //     margin-left: auto;
    //     margin-right: auto;
    //     height: auto;
    //     max-width: 100%;
    //     max-height: 100%;
    // }

    // &.top-one {
    //     height: 450px;
    //     max-height: 450px;

    //     // @media screen and (max-width: 599px) {
    //     //     flex: 1 0 100%;
    //     // }
    //     // @media screen and (min-width: 600px) and (max-width: 959px) {
    //     //     flex: 1 0 40%;
    //     // }

    //     .escort-name {
    //         font-size: 25px;
    //     }

    //     .main-img {
    //         height: 450px;
    //     }
    // }

    // &.top-two {
    //     height: 350px;
    //     max-height: 350px;

    //     .escort-name {
    //         font-size: 20px;
    //     }

    //     .escort-age-city {
    //         bottom: 60px;
    //     }

    //     .main-img {
    //         height: 350px;
    //     }
    // }

    // &.top-three {
    //     height: 250px;
    //     max-height: 250px;

    //     .escort-name {
    //         font-size: 15px;
    //     }

    //     .escort-age-city {
    //         bottom: 55px;
    //     }

    //     .main-img {
    //         height: 250px;
    //     }
    // }
}

/******************/
/* ANIMATION RING */
/******************/
@-moz-keyframes ring-effect-circle-anim {
    0% {
        transform: rotate(0) scale(0.5) skew(1deg);
        opacity: 0.1;
    }
    30% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.5;
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.1;
    }
}

@-webkit-keyframes ring-effect-circle-anim {
    0% {
        transform: rotate(0) scale(0.5) skew(1deg);
        opacity: 0.1;
    }
    30% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.5;
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.1;
    }
}

@-o-keyframes ring-effect-circle-anim {
    0% {
        transform: rotate(0) scale(0.5) skew(1deg);
        opacity: 0.1;
    }
    30% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.5;
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.1;
    }
}

@keyframes ring-effect-circle-anim {
    0% {
        transform: rotate(0) scale(0.5) skew(1deg);
        opacity: 0.1;
    }
    30% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.5;
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.1;
    }
}

@-moz-keyframes ring-effect-circle-fill-anim {
    0% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.2;
    }
    100% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
}

@-webkit-keyframes ring-effect-circle-fill-anim {
    0% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.2;
    }
    100% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
}

@-o-keyframes ring-effect-circle-fill-anim {
    0% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.2;
    }
    100% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
}

@keyframes ring-effect-circle-fill-anim {
    0% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
        opacity: 0.2;
    }
    100% {
        transform: rotate(0) scale(0.7) skew(1deg);
        opacity: 0.2;
    }
}

@-moz-keyframes ring-effect-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
    }
}

@-webkit-keyframes ring-effect-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
    }
}

@-o-keyframes ring-effect-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
    }
}

@keyframes ring-effect-circle-img-anim {
    0% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg);
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg);
    }
}
